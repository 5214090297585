<!--
  论文详情
-->
<template>
  <div class="resource_detail" v-loading="loading">
    <div class="detail_left">
      <div class="base_info module_box tw-mb-20px">
        <div class="module_box_header">
          <p>成果详情</p>
        </div>
        <div class="module_box_main">
          <div class="main_header">
            <div class="header_left">
              <h1>{{ detail.title }}</h1>
              <div class="tag">{{ detail.achievementType }}</div>
            </div>
          </div>
          <div class="main_content">
            <p class="description">{{ detail.description ? detail.description : '暂无描述' }}</p>
          </div>
        </div>
      </div>
      <div
        class="other_info module_box tw-mb-20px">
        <div class="module_box_header">
          <p>详细信息</p>
        </div>
        <div class="module_box_main">
          <el-row>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  成果持有单位：
                </div>
                <div class="item_value">
                  {{ detail.holdUnit }}
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  成果第一完成人：
                </div>
                <div class="item_value">
                  {{ detail.firstCompletedBy }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  所在区域：
                </div>
                <div class="item_value">
                  {{ detail.region }}
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  产业特长：
                </div>
                <div class="item_value">
                  {{ getIndustrySectorNames(detail.industries) }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  技术领域：
                </div>
                <div class="item_value">
                  {{ getTechnicalNames(detail.technical) }}
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  成熟度：
                </div>
                <div class="item_value">
                  {{ detail.maturity }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  合作方式：
                </div>
                <div class="item_value">
                  {{ detail.cooperationMode ? detail.cooperationMode.toString() : '' }}
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  交易价格：
                </div>
                <div class="item_value">
                  <template v-if="detail.price === '面议'">
                    面议
                  </template>
                  <template v-else>
                    ￥{{ detail.price }}元
                  </template>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  成果资料：
                </div>
                <div class="item_value">
                  <file-list
                    :list="detail.attachments || []">
                  </file-list>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  联系人：
                </div>
                <div class="item_value">
                  {{ detail.contactName }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  联系电话：
                </div>
                <div class="item_value">
                  {{ detail.contactNumber }}
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  联系邮箱：
                </div>
                <div class="item_value">
                  {{ detail.contactEmail }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="text_item">
                <div class="item_label">
                  是否为成果代理人：
                </div>
                <div class="item_value">
                  {{ detail.proxyAgent === 1 ? '是' : '否' }}
                </div>
              </div>
            </el-col>
            <el-col :span="12" v-if="detail.proxyAgent === 1">
              <div class="text_item">
                <div class="item_label">
                  代理人单位：
                </div>
                <div class="item_value">
                  {{ detail.proxyUnit }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" v-if="detail.proxyAgent === 1">
              <div class="text_item">
                <div class="item_label">
                  代理协议：
                </div>
                <div class="item_value">
                  <file-list
                    :list="detail.agentAgreement || []">
                  </file-list>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="detail_right">
      <div class="recommend_list module_box tw-mb-20px">
        <div class="module_box_header">
          <p>其他相关资源推荐</p>
        </div>
        <div class="module_box_main">
          <router-link
            tag="a"
            :to="`/achievement-detail/${item.id}?from=recommend`"
            target="_blank"
            class="recommend_item"
            v-for="item in recommendList"
            :key="item.id">
            <div class="item_header">
              <h1>{{ item.title }}</h1>
              <p>
                <template v-if="item.price === '面议'">
                  面议
                </template>
                <template v-else>
                  ￥{{ item.price }}元
                </template>
              </p>
            </div>
            <div class="item_footer">
              <el-image
                v-if="item.avatarUrl"
                style="width: 24px; height: 24px"
                :src="item.avatarUrl"
                fit="cover">
              </el-image>
              <img v-else src="../../../assets/images/default_avatar.png" width="24" height="24" style="object-fit: cover;" />
              <p>{{ item.publisherName }}</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fileList from '@/components/upload-file/files-list.vue'
import * as api from '@/api'
export default {
  components: {
    fileList
  },
  data () {
    return {
      loading: false,
      // 详情
      detail: {},
      recommendList: []
    }
  },
  computed: {
    // 论文id
    id () {
      return this.$route.params.id
    },
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    },
    // 行业领域下拉列表
    industrySectorList () {
      return this.$store.state.industrySectorList
    },
    // 拿到技术领域的id字符串
    getTechnicalNames () {
      return (technical = []) => {
        let result = ''

        this.technicalList.forEach(item => {
          if (technical.includes(item.id)) {
            result += result === '' ? item.name : ',' + item.name
          }
        })
        return result
      }
    },
    // 拿到行业领域的id字符串
    getIndustrySectorNames () {
      return (idList = []) => {
        let result = ''

        this.industrySectorList.forEach(item => {
          if (idList.includes(item.id)) {
            result += result === '' ? item.name : ',' + item.name
          }
        })
        return result
      }
    }
  },
  created () {
    // 获取成果详情
    this.getAchievementDetail()
  },
  methods: {
    // 获取成果详情
    getAchievementDetail () {
      this.loading = true
      api.getAchievementDetail(this.id).then(res => {
        if (res.data.code === 0) {
          this.detail = res.data.data

          // 获取成果推荐列表
          this.getAchievementRecommend()
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 获取成果推荐列表 传入同 成果类型
    getAchievementRecommend () {
      api.getAchievementRecommend({
        achievementType: this.detail.achievementType
      }).then(res => {
        if (res.data.code === 0) {
          console.log('需求推荐列表', res.data.data)
          this.recommendList = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .resource_detail {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .detail_left {
      flex: 1;
      margin-right: 20px;
      .base_info {
        .module_box_main {
          padding-bottom: 30px;
          .main_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 67px;
            border-bottom: 1px solid #DCDFE6;
            .header_left {
              display: flex;
              height: 100%;
              align-items: center;
              & > h1 {
                font-size: 26px;
                font-weight: bold;
                line-height: 37px;
                color: #000000;
                margin-right: 30px;
              }
            }
          }
          .main_content {
            padding: 24px 0 0px 0;
            & > .description {
              font-size: 14px;
              font-weight: 500;
              line-height: 30px;
              color: #303133;
              margin-bottom: 22px;
            }
            .keywords {
              display: flex;
              font-size: 14px;
              line-height: 30px;
              margin-bottom: 10px;
              .keywords_label {
                width: 90px;
                color: #909399;
              }
              .keywords_value {
                color: #303133;
                & > span {
                  &+span {
                    margin-left: 10px;
                  }
                }
              }
            }
            .text_list {
              .text_item {
                display: flex;
                // max-width: 320px;
                font-size: 14px;
                line-height: 30px;
                margin-bottom: 10px;
                .item_label {
                  // flex: 0 0 90px;
                  width: 100px;
                  color: #909399;
                }
                .item_value {
                  // flex: 0 0 230px;
                  width: 220px;
                  color: #303133;
                }
              }
            }
          }
        }
      }
      .other_info {
        background: #fff url('../../../assets/images/detail/about_the_company.png') right bottom no-repeat;
        .module_box_main {
          padding-bottom: 30px;
          .text_item {
            display: flex;
            // max-width: 320px;
            font-size: 14px;
            line-height: 30px;
            margin-bottom: 10px;
            .item_label {
              // flex: 0 0 90px;
              width: 150px;
              padding-right: 10px;
              color: #909399;
            }
            .item_value {
              // flex: 0 0 230px;
              width: 280px;
              color: #303133;
            }
          }
        }
      }
    }
    .detail_right {
      flex: 0 0 450px;
      .recommend_list {
        .module_box_main {
          padding-bottom: 20px;
          .recommend_item {
            display: block;
            padding-bottom: 10px;
            border-bottom: 1px dashed #DCDFE6;
            .item_header {
              display: flex;
              height: 50px;
              align-items: center;
              justify-content: space-between;
              & > h1 {
                font-size: 18px;
                font-weight: bold;
                line-height: 25px;
                color: #606266;
                padding-right: 20px;
              }
              & > p {
                font-size: 16px;
                font-weight: bold;
                line-height: 22px;
                color: #909399;
              }
            }
            .item_footer {
              display: flex;
              align-items: center;
              height: 30px;
              & > p {
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                color: #909399;
                margin-left: 10px;
              }
            }
            &+.recommend_item {
              margin-top: 10px;
            }
          }
        }
        .module_box_footer {
          padding-bottom: 20px;
          .refresh_btn {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #3473E6;
            cursor: pointer;
          }
        }
      }
    }

    .module_box {
      display: block;
      width: 100%;
      background-color: #fff;
      padding: 0 20px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      overflow: hidden;
      .module_box_header {
        display: flex;
        align-items: center;
        height: 50px;
        margin-bottom: 10px;
        &::before {
          content: "";
          width: 3px;
          height: 20px;
          background: #3473E6;
          border-radius: 1px;
          margin-right: 10px;
        }
        & > p {
          font-size: 16px;
          font-weight: bold;
          line-height: 22px;
          color: #31394C;
        }
      }
      .module_box_main {
        padding: 0 10px;
      }
      .module_box_footer {
        padding: 0 10px;
      }
    }

    .tag {
      height: 24px;
      padding: 0 5px;
      background: rgba(52, 115, 230, 0);
      border: 1px solid #DCDFE6;
      border-radius: 2px;
      font-size: 12px;
      font-weight: bold;
      line-height: 24px;
      color: #3473E6;
      text-align: center;
      white-space: nowrap;
    }
  }
</style>
